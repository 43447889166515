import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import anilji from '../../media/anil_tripathi.png'
import anilji2 from '../../media/anil_tripathi.jpg'
import thali from '../../media/plate_with_food.png'
import Typewriter from 'typewriter-effect'
import '../style.css'
import '../main.css'
import GoToTop from '../../js/GoToTop'

const Home = () => {

  useEffect(() => {
    document.title = "अनिल त्रिपाठी - होम"
  }, [])

  return (
    <div>

      <section id="home" className="home">
        <div className="container">

          <div className="row align-items-center">

            <div className="col">
              <img data-aos="fade-down" data-aos-delay="400" className="rounded mx-auto d-block" src={anilji} alt="Anil Tripathi"/>	
            </div>
            <div className="text-center col mt-sm-0 mt-3">
              <div className="credit">
                <h1 data-aos="fade-right" data-aos-delay="300"><strong>अनिल त्रिपाठी</strong></h1>
                <h3 className='heading3'>विधायक, मेंहदावल</h3>
                <h5>संत कबीर नगर, उत्तर प्रदेश</h5>
                {/* <Typewriter
                  options={{
                    strings: ['राष्ट्रवादी','ईमानदार','कर्मठ'],
                    autoStart: true,
                    loop: true,
                  }}
                /> */}
              </div>
            </div>

          </div>
        
        </div>

      </section>

        {/* <div className="container vsdiv">
          <div className="vidhansabha mx-auto d-block" data-aos="fade-up" data-aos-delay="500">

            <div className="row align-items-center">
              <div className="col-lg-5">
                <div>
                  <h4 className='heading4'>चुनाव चिन्ह</h4>
                  <img src={thali} alt="भोजन भारी थाली"/>
                  <h4 className='heading4'><strong>भोजन भरी थाली</strong></h4>
                </div>
              </div>
              <div className="col-lg-7">
                <div>
                  <h3 className='heading3'><strong>अनिल त्रिपाठी</strong></h3>
                  <h5 className='heading5'>विधायक, भाजपा/निषाद पार्टी</h5>
                  <h3 className='heading3'><strong>मेंहदावल विधान सभा (312)</strong></h3>
                  <h5 className='heading5'>संत कबीर नगर</h5>
                </div>
              </div>
            </div>
          </div>

        </div> */}

      <section id="about" className="home-about">
        <div className="container">
          <div className="section-title mt-4">
            <h2>अनिल त्रिपाठी के बारे में</h2>
          </div>

          <div className="row align-items-center">
              <div className="col-lg-5 text-center">
                <img className="" src={anilji2} alt="Anil Tripathi"/>
              </div>
              <div className="col-lg-7">
                <p className="lead text-left py-3">अनिल कुमार त्रिपाठी का जन्म 31 मार्च, 1965 को उत्तर प्रदेश राज्य के संतकबीर नगर जिले में स्थित मेंहदावल तहसील के ग्राम करमां में हुआ। इनके पिता का नाम स्व० दयाशंकर त्रिपाठी एवं माता का नाम स्व० श्रीमती लीलावती त्रिपाठी है। त्रिपाठी जी का मूल निवास बेलौली है।</p>
                <Link className="btn" to='/about'>और अधिक जानें</Link>
              </div>
          </div>

        </div>
      </section>
      <GoToTop />
    </div>
  )
}

export default Home