import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import '../style.css'
import '../main.css'

const Navbar = () => {
    const [topbar, setTopbar] = useState(false)

    const showTopbar = () => setTopbar(!topbar)

    return (
        <div>
            <nav id='navbar' className='navbar navbar-expand-lg fixed-top b-dark'>
                <div className="container navbar-container">

                    <div className=''>
                        <Link className="navbar-brand nav-color" to='/'><h4><strong>अनिल </strong>त्रिपाठी</h4></Link>
                    </div>

                    <div className="d-flex nav-ham">

                        <ul className="navbar-nav ml-auto nav-color">
                            <li className="nav-item mr-2 active">
                                <Link className="nav-link nav-color" to='/'>होम</Link>
                            </li>
                            <li className="nav-item mr-2">
                                <Link className="nav-link nav-color" to='/about'>परिचय</Link>
                            </li>
                            <li className="nav-item mr-2">
                                <Link className="nav-link nav-color" to='/contact'>संपर्क करें</Link>
                            </li>
                        </ul>
                        <div className="social-links text-lg-right text-center">
                            <a href="https://www.facebook.com/Anil4Bharat" className="nav-color" target="_blank"><i className="bi-facebook"></i></a>
                            <a href="https://www.twitter.com/Anil4Bharat" className="nav-color" target="_blank"><i className="bi-twitter"></i></a>
                            <a href="https://www.instagram.com/Anil4Bharat" className="nav-color" target="_blank"><i className="bi-instagram"></i></a>
                            <a href="mailto:anil4mehdawal@gmail.com"><i className="bi-envelope"></i></a>
                        </div>
                        
                        <div className={topbar ? 'div1 cross' : 'div1'} onClick={showTopbar}>
                            <div className='sub1'></div>
                            <div className='sub2'></div>
                            <div className='sub3'></div>
                        </div>
                    </div>



                </div>
                <div className={topbar ? 'sidebar active' : 'sidebar'}>
                    <div className="container h-100">
                        <div>
                            <ul className="sidebar-list">
                                <li className="nav-item mr-2 active">
                                    <Link onClick={showTopbar} className="nav-link nav-color" to='/'>होम</Link>
                                </li>
                                <li className="nav-item mr-2">
                                    <Link onClick={showTopbar} className="nav-link nav-color" to='/about'>परिचय</Link>
                                </li>
                                <li className="nav-item mr-2">
                                    <Link onClick={showTopbar} className="nav-link nav-color" to='/contact'>संपर्क करें</Link>
                                </li>
                            </ul>
                            <div className="social-links text-lg-right text-center">
                                <a href="https://www.facebook.com/AnilTripathiMehdawal" className="nav-color" target="_blank"><i className="bi-facebook"></i></a>
                                <a href="https://www.twitter.com/AnilTripathiIN" className="nav-color" target="_blank"><i className="bi-twitter"></i></a>
                                <a href="https://www.instagram.com/AnilTripathiIN" className="nav-color" target="_blank"><i className="bi-instagram"></i></a>
                                <a href="mailto:anil4mehdawal@gmail.com"><i className="bi-envelope"></i></a>
                            </div>
                        </div>
                    </div>
                </div>


            </nav>

        </div>
    )
}

export default Navbar