import React, { useEffect } from 'react'
import anilji from '../../media/anil_tripathi.jpg'
import '../style.css'
import '../main.css'
import GoToTop from '../../js/GoToTop'

const Contact = () => {

  useEffect(() => {
    document.title = "अनिल त्रिपाठी - संपर्क करें"
  }, [])

  return (
    <div>
        <section id="about" className="about">
            <div className="container">
                <div className="section-title">
                    <h2>संपर्क करें</h2>
                </div>
                <i className="bi-envelope"></i> <a href="mailto:anil4mehdawal@gmail.com">anil4mehdawal@gmail.com</a><br/>
                <i className="bi-facebook"></i> <a href="https://www.facebook.com/Anil4Bharat" target="_blank">Anil4Bharat</a><br/>
                <i className="bi-twitter"></i> <a href="https://www.twitter.com/Anil4Bharat" target="_blank">Anil4Bharat</a><br/>
                <i className="bi-instagram"></i> <a href="https://www.instagram.com/Anil4Bharat" target="_blank">Anil4Bharat</a><br/><br/>
                <address><i className="bi-geo-alt-fill"></i> ग्राम व पोस्ट - करमां कला,<br/> ब्लॉक व तहसील - मेंहदावल,<br/> जनपद - संतकबीर नगर</address>

            </div>
        </section>
        <GoToTop/>
    </div>
  )
}

export default Contact