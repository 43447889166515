import React, { useEffect } from 'react'
import anilji from '../../media/anil_tripathi.jpg'
import '../style.css'
import '../main.css'
import GoToTop from '../../js/GoToTop'

const About = () => {

  useEffect(() => {
    document.title = "अनिल त्रिपाठी - परिचय"
  }, [])

  return (
    <div>
        <section id="about" className="about">
            <div className="container text-center">
                <div className="section-title">
                    <h2>संक्षिप्त परिचय</h2>
                </div>
                <img className="float-lg-right float-none ml-lg-4 mb-lg-3" src={anilji} alt=""/>
                <p className="lead mt-4 text-left">अनिल कुमार त्रिपाठी का जन्म 31 मार्च, 1965 को उत्तर प्रदेश राज्य के संतकबीर नगर जिले में स्थित मेंहदावल तहसील के ग्राम करमां में हुआ। इनके पिता का नाम स्व० दयाशंकर त्रिपाठी एवं माता का नाम स्व० श्रीमती लीलावती त्रिपाठी है। त्रिपाठी जी का मूल निवास बेलौली है।</p>
                <p className="lead mt-4 text-left">अनिल जी सामाजिक कार्यों में भी अपना भरपूर योगदान देते रहें हैं जिसमें हज़ारों लोगों के इलाज में सहयोग, गरीब कन्याओं की शादी एवं बच्चों की शिक्षा भी शामिल है।</p>
                <p className="lead mt-4 text-left">त्रिपाठी जी अपने गृह क्षेत्र मेंहदावल से राजनीतिक कार्य में 18 वर्ष से संलग्न है। वर्ष 2007 में निर्दल प्रत्याशी के रूप में चतुर्थ स्थान एवं 2012 में पीस पार्टी के प्रत्याशी के रूप में द्वितीय स्थान प्राप्त किया। वर्ष 2017 में ब.स.पा. के प्रत्याशी के रूप में 44062 मत के साथ द्वितीय स्थान पर रहें।</p>
                <p className="lead mt-4 text-left"><strong>उत्तर प्रदेश विधान सभा चुनाव 2022</strong> में अनिल त्रिपाठी जी <strong>भाजपा एवं निषाद पार्टी गठबंधन(एन.डी.ए.)</strong> के <strong>312 - मेंहदावल विधान सभा</strong> क्षेत्र से साझा प्रत्याशी हैं|</p>
            </div>
        </section>
        <GoToTop/>
    </div>
  )
}

export default About